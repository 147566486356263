import styled, { css } from 'styled-components';

const BoxStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3em;
  text-align: center;
  width: calc(70vw/3);
  margin: 1em;
  background-color: ${(props) => props.boxColor ? props.boxColor : "white"};
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

  ${props => props.$primary && css`
    background: #62272E;
    color: white;
  `}
`;

const BoxTitle = styled.h2`
  font-size: 1.6em;
  text-transform: uppercase;
  color: ${(props) => props.color || "#333"};

    ${props => props.$primary && css`
    color: black;
  `}
`;

const BoxSubtitle = styled.h3`
  font-size: 1.6em;
  font-weight: 400;
  text-transform: Capitalize;
  margin-bottom: 0.5em;
  color: ${(props) => props.color || "#333"};

    ${props => props.$primary && css`
    color: black;
  `}
`;

const BoxText = styled.p`
  font-size: 1em;
  margin-bottom: 0.5em;
  height: 5em;
  overflow: hidden;
  display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  color: ${(props) => props.color || "#777"};

    ${props => props.$primary && css`
    color: white;
  `}
`;

const BoxList = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 1em;
  margin: 1em;
  padding: 0;
  width: 100%;
  align-items: center;
  list-style-type: none;
  color: ${(props) => props.color || "#444"};

    ${props => props.$primary && css`
    color: #999;
  `}
`;

const ListItem = styled.li`
  padding: 1em;
  font-weight: 500;
  font-stretch: expanded;
  background-color: #f8f8f8;
  border-radius: 5px;
  color: #666;

  &:hover {
    background-color: #ececec;
  }
`


const Box = ({ $primary, boxColor, children }) => {
    return (
        <BoxStyle $primary={$primary} boxColor={boxColor}>
            {children}
        </BoxStyle>
    );
}

export { Box, BoxTitle, BoxSubtitle, BoxText, BoxList, ListItem };