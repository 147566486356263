// src/components/Navbar.js

import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import axios from 'axios';
import Button from "../components/Button"
import Logo from "../components/Logo"
import ScrollLink from '../components/ScrollLink';  

function Navbar() {
  const { user, setUser } = useContext(AuthContext);

  const handleLogout = () => {
    axios.get('/users/logout', { withCredentials: true })
      .then(() => {
        setUser(null);
      })
      .catch(error => {
        console.error('Error logging out:', error);
      });
  };

  return (
    <nav style={navStyle}>
      <div style={divStyle}> {/* left-aligned items */}
        <Logo $primary={false} />
        <ScrollLink to="/#all-courses" style={linkStyle}>All Courses</ScrollLink>
        <a href="https://www.mq.edu.au/" target="_blank" style={linkStyle}>MQ Website</a>
        <a href="https://unitguides.mq.edu.au/" target="_blank" style={linkStyle}>Unit Guide</a>
      </div>
      <div style={divStyle}> {/* right-aligned items */}
        {user ? (
          <>
            <p style={{filter: 'opacity(0.7)', marginRight: '30px'}}>{user.email}</p>
            <Link to="/my-reviews"><Button $primary={true} buttonShadow="none" label="My Account" /></Link>
            <button onClick={handleLogout}><Button $primary={false} label="Log out" /></button>
          </>
        ) : (
          <>
            <Link to="/login"><Button $primary={true} buttonShadow="none" label="Login" /></Link>
            <Link to="/register"><Button $primary={false} label="Register" /></Link>
          </>
        )}
      </div>
    </nav>
  );
}

const navStyle = {
  backgroundColor: '#76232F',
  width: '100vw',
  padding: '4px 0',
  display: 'flex',
  justifyContent: 'space-between',
  position: 'fixed',
  top: '0',
  color: 'white',
  zIndex: '1',
  fontFamily: '"Roboto", sans-serif',
};

const linkStyle = {
  marginLeft: '60px',
  display: 'flex',
  alignItems: 'center',
  textTransform: 'capitalize',
};

const divStyle = {
  display: 'flex',
  alignItems: 'center',
  marginRight: '60px'
}

export default Navbar;