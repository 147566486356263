// src/pages/HomePage.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, BoxTitle, BoxSubtitle, BoxText, BoxList, ListItem } from "../components/Box"
import Button from "../components/Button"
import Banner from "../components/Banner"
import bannerImage from "../assets/1cc.jpg"
import SortDropdown from '../components/SortDropdown';

function HomePage() {
  const [courses, setCourses] = useState([]);
  const [sortCriteria, setSortCriteria] = useState('overallRating'); // Default sort by overallRating
  const [expandedCourses, setExpandedCourses] = useState({});
  const [loadingReviews, setLoadingReviews] = useState({});
  const [generatingSummary, setGeneratingSummary] = useState({}); // New state for summary generation

  useEffect(() => {
    fetchCourses();
  }, [sortCriteria]);

  const fetchCourses = () => {
    axios
      .get('/courses')
      .then((response) => {
        let sortedCourses = response.data.sort(
          (a, b) => b[sortCriteria] - a[sortCriteria]
        );
        setCourses(sortedCourses);
      })
      .catch((error) => {
        console.error('Error fetching courses:', error);
      });
  };

  const toggleReviews = (courseId) => {
    const isExpanded = expandedCourses[courseId];
    setExpandedCourses(prev => ({ ...prev, [courseId]: !isExpanded }));

    if (!isExpanded) {
      setLoadingReviews(prev => ({ ...prev, [courseId]: true }));
      axios.get(`/reviews/course/${courseId}`)
        .then(response => {
          setCourses(prevCourses =>
            prevCourses.map(course =>
              course._id === courseId ? { ...course, reviews: response.data } : course
            )
          );
        })
        .catch(error => {
          console.error('Error fetching reviews:', error);
        })
        .finally(() => {
          setLoadingReviews(prev => ({ ...prev, [courseId]: false }));
        });
    }
  };

  // New function to generate summary
  const generateSummary = (courseId) => {
    setGeneratingSummary(prev => ({ ...prev, [courseId]: true }));
    axios.post(`/courses/${courseId}/summary`)
      .then(response => {
        setCourses(prevCourses =>
          prevCourses.map(course =>
            course._id === courseId ? { ...course, summary: response.data.summary } : course
          )
        );
      })
      .catch(error => {
        console.error('Error generating summary:', error);
      })
      .finally(() => {
        setGeneratingSummary(prev => ({ ...prev, [courseId]: false }));
      });
  };

  // Function to get styling based on sentiment
  function getSentimentStyle(sentiment) {
    switch (sentiment) {
      case 'positive':
        return { color: 'green' };
      case 'negative':
        return { color: 'red' };
      case 'neutral':
        return { color: 'gray' };
      default:
        return { color: 'gray' }; // Default style for undefined sentiment
    }
  }

  const handleSortSelection = (sortValue) => {
    console.log('Sorted by:', sortValue);
    // NEED TO IMPLEMENT: Update the sortCriteria state with the selected value
  };

  return (
    <main>
      <article id="intro">
        <Banner backgroundImage={bannerImage}>
          <div style={bannerStyle}>
            <h1 style={{  marginBottom: '0.3em', lineHeight: '1', overflow: 'hidden', textTransform: 'uppercase' }}>Macquarie<br></br>Course Reviews</h1>
          </div>
        </Banner>
        <section style={introStyle}>
          <h3>Welcome to iReview!</h3>
          <p id = "intro-p" style={{ color: '#999', lineHeight: '1.5', textTransform: 'uppercase', letterSpacing: '0.2em' }}>Getting ready to choose your next course at Macquarie University?<br></br>Find the perfect courses to match your academic goals.</p>
        </section>
      </article>
      <hr id="all-courses"/>
      <article className="content" >
        <section style={headerStyle}>
          <h2>All Courses</h2>
          <div style={ContainerStyle}>
            <input type="text" placeholder="Search..." id="search" />
            <SortDropdown onSortChange={handleSortSelection} />
          </div>
        </section>
        <section style={sectionStyle}>
          {courses.map((course) => (
            <Box key={course._id}>
              <BoxTitle>{course.code}</BoxTitle>
              <BoxSubtitle>{course.name}</BoxSubtitle>
              <BoxText>{course.description}</BoxText>
              <BoxList $primary={true}>
                <ListItem>Overall Rating: {course.overallRating.toFixed(2)}</ListItem>
                <ListItem>Difficulty Level: {course.difficultyLevel.toFixed(2)}</ListItem>
                <ListItem>Workload: {course.workload.toFixed(2)}</ListItem>
                <ListItem>Teaching Quality: {course.teachingQuality.toFixed(2)}</ListItem>
              </BoxList>

              <div style={buttonContainerStyle}>
                <button onClick={() => toggleReviews(course._id)}>
                  <Button
                    $primary={expandedCourses[course._id]}
                    label={expandedCourses[course._id] ? 'Hide Reviews' : 'Show Reviews'}
                  />
                </button>
                <button onClick={() => generateSummary(course._id)} >
                  <Button $primary={false} label="Generate Summary" />
                </button>
              </div>

              {generatingSummary[course._id] && <BoxText>Generating summary...</BoxText>}
              {course.summary && <BoxText style={{ fontStyle: 'italic' }}>{course.summary}</BoxText>}
              {
                expandedCourses[course._id] && (
                  <div style={reviewsContainerStyle}>
                    {loadingReviews[course._id] ? (
                      <p>Loading reviews...</p>
                    ) : course.reviews && course.reviews.length > 0 ? (
                      <ul style={reviewListStyle}>
                        {course.reviews.map((review) => (
                          <li key={review._id} style={reviewItemStyle}>
                            <p>{review.text}</p>
                            <p>
                              Sentiment:{' '}
                              {review.sentiment ? (
                                <span style={getSentimentStyle(review.sentiment)}>
                                </span>
                              ) : (
                                <span style={{ color: 'gray' }}>Not Available</span>
                              )}
                            </p>
                            <p style={reviewDateStyle}>
                              {new Date(review.date).toLocaleDateString()}
                            </p>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p>No reviews yet.</p>
                    )}
                  </div>
                )
              }
            </Box>
          ))
          }
        </section>
      </article>
    </main >
  );
}

const introStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'white',
  height: '30vh',
  gap: '1em',
  textAlign: 'center',
}

const bannerStyle = {
  margin: '0 15vw',
  display: 'flex',
  alignItems: 'flex-end',
  color: 'white',
  zIndex: '1',
  fontSize: '2em',
}

const headerStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '1em',
}

const ContainerStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
};

const sectionStyle = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  alignItems: 'stretch',
  justifyContent: 'space-between',
}

const buttonContainerStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}

const reviewsContainerStyle = {
  marginTop: '10px',
};

const reviewListStyle = {
  listStyleType: 'none',
  paddingLeft: 0,
};

const reviewItemStyle = {
  borderTop: '1px solid #ccc',
  paddingTop: '10px',
  marginTop: '10px',
};

const reviewDateStyle = {
  fontSize: '0.8em',
  color: '#555',
};


export default HomePage;