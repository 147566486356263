// src/App.js

import React from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import './App.css';

import Navbar from "./components/Navbar";
import HomePage from "./pages/HomePage";
import RegisterPage from "./pages/RegisterPage";
import LoginPage from "./pages/LoginPage";
import MyReviewsPage from "./pages/MyReviewsPage";
import AddReviewPage from "./pages/AddReviewPage";
import { AuthProvider } from "./context/AuthContext";
import AddCoursePage from './pages/AddCoursePage';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Navbar />
        <PageRoutes />
      </Router>
    </AuthProvider>
  );
}

function PageRoutes() {
  const location = useLocation();
  const fullScreen = location.pathname === '/login' || location.pathname === '/register';

  return (
    <div className={fullScreen ? 'fullscreen' : 'default'}>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/my-reviews" element={<MyReviewsPage />} />
        <Route path="/add-review" element={<AddReviewPage />} />
        <Route path="/add-course" element={<AddCoursePage />} />
      </Routes>
    </div>
  )
}

export default App;
