import React from 'react';
import Dropdown from './Dropdown';

function CourseDropdown({ courses, selectedCourseId, onCourseChange }) {
    const courseOptions = courses.map(course => ({
        _id: course._id,
        display: `${course.code}: ${course.name}`
    }));

    const listStyle = {
        position: "absolute",
        top: "calc(8em - 11px)",
    }

    return (
        <Dropdown
            options={courseOptions}
            value={selectedCourseId}
            onChange={(e) => onCourseChange(e.target.value)}
            valueField="_id"
            displayField="display"
            $primary = {true}
            buttonStyle={{ padding: "calc(0.5em + 1.5px) 1em" }}
            listStyle={listStyle}
        />
    );
}

export default CourseDropdown;