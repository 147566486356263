import styled, { css } from 'styled-components';

const ButtonStyle = styled.a`
  background-color: ${(props) => props.buttonColor? props.buttonColor:"white"};
  border-radius: 20px;
  color: ${(props) => props.textColor? props.textColor:"#62272E"};
  padding: 0.5em 1em;
  box-shadow: ${(props) => props.buttonShadow? props.buttonShadow:"rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"};
  display: block;
  width: 100%;
  text-align: center;
  white-space: normal;
  word-wrap: break-word;
  margin: 1em;
  z-index: 3;

  ${props => props.$primary && css`
    background-color: #76232F;
    color: ${(props) => props.textColor? props.textColor:"white"};
  `}

  &:hover {
    cursor: pointer;
    filter: brightness(0.85);
  }
`;

const Container = styled.div`
  text-align: center;
  display: flex;
`;

const Button = ({ $primary, label, buttonColor, textColor, buttonShadow }) => {
  return (
      <Container>
        <ButtonStyle $primary={$primary} buttonColor={buttonColor} textColor={textColor} buttonShadow={buttonShadow}>{label}</ButtonStyle>
      </Container>
  );
}

export default Button;