import React, { useState } from 'react';
import Dropdown from './Dropdown'; // Assuming Dropdown is a generic component

const SortDropdown = ({ onSortChange }) => {
  const [sortCriteria, setSortCriteria] = useState('overallRating');
  
  const sortOptions = [
    { _id: 'overallRating', name: 'Overall Rating' },
    { _id: 'difficultyLevel', name: 'Difficulty Level' },
    { _id: 'workload', name: 'Workload' },
    { _id: 'teachingQuality', name: 'Teaching Quality' }
  ];

  const handleSortChange = (value) => {
    console.log("Sort criteria changed to:", value);
    setSortCriteria(value);
    onSortChange(value);
  };

  const buttonStyle = {
    padding: "0 1em",
    fontSize: "0.9em",
    width: "10em",
    boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
  }

  const listStyle = {
    width: "calc(11em - 4px)",
    right: "1em",
    top: "calc(3em - 2px)",
    left: "auto",
    justifyContent: "flex-end",
    boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
  }

  console.log("Current Sort Criteria:", sortCriteria);

  return (
    <Dropdown
      options={sortOptions}
      value={sortCriteria}
      onChange={handleSortChange}
      valueField="_id"
      displayField="name"
      containerStyle={{   width: "auto", justifyContent: "flex-end" }}
      buttonStyle={buttonStyle}
      listStyle={listStyle}
      itemStyle={{ padding: "0.4em 1em", fontSize: "0.9em", }} />
  );
}

export default SortDropdown;