import styled, { css } from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../assets/iReview.png';  // Make sure the path matches where your image is stored

const StyledLink = styled(Link)`
  display: flex;
  margin-left: 60px;
  justify-self: center;

  ${props => props.$primary ? css`
    position: absolute;
    top: 22px;
    right: 60px;
    margin: 0;
    z-index: 3;
  ` : css`
    position: relative;
  `}
`;

const LogoImage = styled.img`
  max-width: 100%;
  height: auto;
  filter: contrast(1.1);

   ${props => props.$primary && css`
    filter: opacity(0.8);
  `}
`;

const Logo = ({ $primary }) => {
    const navigate = useNavigate();
    
    const handleClick = (event) => {
    event.preventDefault(); // Prevent default link behavior
    navigate('/'); // Navigate to homepage
    window.scrollTo(0, 0); // Scroll to the top of the page
  };
  return (
    <StyledLink to="/" onClick={handleClick} $primary={$primary}>
      <LogoImage src={logo} alt="iReview Logo" $primary={$primary}/>
    </StyledLink>
  );
}

export default Logo;