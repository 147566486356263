// src/pages/AddReviewPage.js

import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import PageTitle from '../components/PageTitle';
import Button from '../components/Button';
import StarRating from '../components/StarRating';
import CourseDropdown from '../components/CourseDropdown';

function AddReviewPage() {
  const [courses, setCourses] = useState([]);
  const [courseId, setCourseId] = useState('');
  const [overallRating, setOverallRating] = useState(1);
  const [difficultyLevel, setDifficultyLevel] = useState(1);
  const [workload, setWorkload] = useState(1);
  const [teachingQuality, setTeachingQuality] = useState(1);
  const [text, setText] = useState('');
  const [error, setError] = useState('');
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate('/login');
    } else {
      fetchCourses();
    }
  }, [user, navigate]);

  const fetchCourses = () => {
    axios.get('/courses')
      .then(response => {
        console.log("Fetched courses:", response.data);
        setCourses(response.data);
        if (response.data.length > 0) {
          setCourseId(response.data[0]._id);
        }
      })
      .catch(error => {
        console.error('Error fetching courses:', error);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post('/reviews', {
      courseId,
      overallRating,
      difficultyLevel,
      workload,
      teachingQuality,
      text,
    }, { withCredentials: true })
      .then(() => {
        navigate('/my-reviews');
      })
      .catch(error => {
        console.error('Error submitting review:', error);
        setError(error.response.data.message || 'Failed to submit review');
      });
  };

  const handleCourseChange = (newId) => {
    setCourseId(newId);
};

  return (
    <main>
      <PageTitle title="Add Review" />
      <article className="content" id="add-review">
        <h3 className="form-title">Review Details</h3>
        {error && <p style={errorStyle}>{error}</p>}
        <form onSubmit={handleSubmit} style={{ position: "relative" }}>
          <div className="form-input" id="review-input">
            <label style={{ marginLeft: '1em' }}>Course Name</label>
            <CourseDropdown
              courses={courses}
              selectedCourseId={courseId}
              onCourseChange={handleCourseChange}
            />
          </div>
          <section className="add-form" id="review-form">
            <div className="form-input" id="review-input">
              <div className="question">
                <label>Rating</label>
                <p>Overall, how would you rate this course on a scale of 1-5?</p>
              </div>
              <StarRating count={5} rating={overallRating} onChange={setOverallRating} />
            </div>
            <div className="form-input" id="review-input">
              <div className="question">
                <label>Difficulty</label>
                <p>How difficult was this course on a scale of 1-5?</p>
              </div>

              <StarRating count={5} rating={difficultyLevel} onChange={setDifficultyLevel} />
            </div>
            <div className="form-input" id="review-input">
              <label>Teaching Quality</label>
              <StarRating count={5} rating={teachingQuality} onChange={setTeachingQuality} />
            </div>
            <div className="form-input" id="review-input">
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <label className="question">Workload</label>
                <small class="unit">hours / week</small>
              </div>
              <div className="slider">
                <input
                  type="range"
                  min="0"
                  max="30"
                  step="5"
                  value={workload}
                  onChange={e => setWorkload(e.target.value)}
                  required
                />
                <div className="slider-labels">
                  {Array.from({ length: 7 }, (_, i) => (
                    <span key={i} style={spanStyle}>
                      {5 * i}
                    </span>
                  ))}
                </div>
              </div>
            </div>
            <div className="form-input" id="review-input">
              <label>Review</label>
              <textarea
                value={text}
                onChange={e => setText(e.target.value)}
                required
              ></textarea>
            </div>
          </section>
          <section className="form-btn">
            <Button $primary={true} label="Submit Review" />
          </section>
        </form>
      </article>
    </main>
  );
}

const spanStyle = {
  width: '30px',
  fontWeight: 'bold',
  textAlign: 'center',
  color: '#888',
};

const errorStyle = {
  color: 'red',
};

export default AddReviewPage;
