// src/pages/MyReviewsPage.js

import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import Button from "../components/Button"
import PageTitle from '../components/PageTitle';

function MyReviewsPage() {
  const [reviews, setReviews] = useState([]);
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate('/login');
    } else {
      fetchMyReviews();
    }
  }, [user, navigate]);

  const fetchMyReviews = () => {
    axios.get('/users/my-reviews', { withCredentials: true })
      .then(response => {
        setReviews(response.data);
      })
      .catch(error => {
        console.error('Error fetching reviews:', error);
      });
  };

  return (
    <main>
      <PageTitle title="My Account" />
      <article className="content" style={containerStyle}>
        <section>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
            <h3 className="form-title">My Reviews</h3>
            <div style={buttonContainerStyle}>
              <Link to="/add-course">
                <Button $primary={false} label="Add New Course" />
              </Link>
              <Link to="/add-review">
                <Button $primary={false} buttonColor="#76232F" textColor="white" label="Add New Review" />
              </Link>
            </div>
          </div>
          <div>
            <ul style={listStyle}>
              {reviews.length > 0 ? (
                reviews.map(review => (
                  <li key={review._id} style={reviewItemStyle}>
                    <h2>{review.courseCode}: {review.courseName}</h2>
                    <p>{review.text}</p>
                    <p>Overall Rating: {review.overallRating}</p>
                    {/* Display other ratings if desired */}
                  </li>
                ))
              ) : (
                <p style={infoStyle}>You don't have any reviews yet.</p>
              )}
            </ul>
          </div>
        </section>
        <section>
          <h3 className="form-title">My Courses</h3>
          <p style={infoStyle}>Select an interesting course and mark as favorite!</p>
        </section>
      </article>
    </main>
  );
}

const containerStyle = {
  display: 'grid',
  gridTemplateRows: '1fr 1fr',
  gridGap: '5em',
};

const buttonContainerStyle = {
  display: 'flex',
  flexDirection: 'row',
};

const listStyle = {
  listStyleType: 'none',
  padding: 0,
};

const reviewItemStyle = {
  borderBottom: '1px solid #000',
  paddingBottom: '10px',
  marginBottom: '10px',
};

const infoStyle = {
  color: 'gray',
  fontSize: '1.2em',
  fontStyle: 'italic',
  lineHeight: '2em',
};

export default MyReviewsPage;
