// src/pages/RegisterPage.js

import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import mq from '../assets/MQU.png';
import Logo from '../components/Logo';

function RegisterPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [academicProgram, setAcademicProgram] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleRegister = (e) => {
    e.preventDefault();
    axios.post('/users/register', { email, password, academicProgram })
      .then(() => {
        navigate('/login');
      })
      .catch(error => {
        console.error('Error registering:', error);
        setError(error.response.data.message || 'Registration failed');
      });
  };

  return (
    <article style={containerStyle}>
        <section className="left">
        <Logo $primary={true}/>
          <h1>Hi there!</h1>
          <p>Join the Macquarie<br></br>student community today!</p>
        <img src={mq} alt="App logo" style={logoStyle} />
        </section>
        <section className="right">
        <div className="background" id="image2"></div>
        <h1>Register</h1>
        {error && <p style={errorStyle}>{error}</p>}
        <form onSubmit={handleRegister}>
          <div>
            <input type="email" value={email} placeholder="Email Address" onChange={e => setEmail(e.target.value)} required />
          </div>
          <div>
            <input type="password" value={password} placeholder="Password" onChange={e => setPassword(e.target.value)} required />
          </div>
          <div>
            <input type="text" value={academicProgram} placeholder="Academic Program" onChange={e => setAcademicProgram(e.target.value)} required />
          </div>
          <button type="submit" className="submitBtn"><p>Sign up</p></button>
        </form>
        <div className="sign">
          <h2>Already have an account?</h2>
          <a href="/login"><h2>Log in</h2></a>
          </div>
          <p id="sign-info">Log in using your Macquarie student email!</p>
        </section>
    </article>
  );
}

const containerStyle = {
  display: "flex",
}

const logoStyle = {
  position: "fixed",
  bottom: "3em",
  left: "3em"
}

const errorStyle = {
  color: 'red',
};

export default RegisterPage;
