// src/pages/LoginPage.js

import React, { useState, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import mq from '../assets/MQU.png';
import Logo from '../components/Logo';

function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { setUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();
    axios
      .post("/users/login", { email, password }, { withCredentials: true })
      .then((response) => {
        setUser(response.data.user);
        navigate("/my-reviews");
      })
      .catch((error) => {
        console.error("Error logging in:", error);
        setError(error.response.data.message || "Login failed");
      });
  };

  return (
    <article style={containerStyle}>
      <section className="left">
        <Logo $primary={true}/>
        <h1>Discover<br></br>Macquarie<br></br>University</h1>
        <p>Your guide to better course decisions<br></br>—with real student feedback</p>
        <img src={mq} alt="App logo" style={{  position: "fixed", bottom: "3em", left: "3em"}} />
      </section>
      <section className="right">
      <div className="background"></div>
          <h1>Welcome Back!</h1>
          {error && <p style={errorStyle}>{error}</p>}
          <form onSubmit={handleLogin}>
            <div>
              <input
                type="email"
                name="email"
                value={email}
                placeholder="Email Address"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div>
              <input
                type="password"
                name="password"
                value={password}
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="submitBtn">
              <p>Log in</p>
            </button>
          </form>
          <div className="sign">
          <h2>New Here?</h2>
          <a href="/register"><h2>Register</h2></a>
          </div>
          <p id="sign-info">Sign up using your Macquarie student email!</p>
      </section>
    </article>
  );
}

const containerStyle = {
  display: "flex",
}

const errorStyle = {
  color: "red",
};

export default LoginPage;
