// src/pages/AddCoursePage.js

import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import PageTitle from '../components/PageTitle';
import Button from '../components/Button';

function AddCoursePage() {
  const [code, setCode] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [error, setError] = useState('');

  if (!user) {
    navigate('/login');
    return null;
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(
        '/courses',
        { code, name, description },
        { withCredentials: true }
      )
      .then(() => {
        navigate('/');
      })
      .catch((error) => {
        console.error('Error adding course:', error);
        setError(error.response.data.message || 'Failed to add course');
      });
  };

  return (
    <main>
      <PageTitle title="Add New Course" />
      <article className="content" id="add-course">
        <h3 className="form-title">Course Details</h3>
        {error && <p style={errorStyle}>{error}</p>}
        <form onSubmit={handleSubmit}>
          <section className="add-form">
            <div className="form-input">
              <label>Unit Code:</label>
              <input
                type="text"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                required
                pattern="[A-Za-z]{4}+[0-9]{4}"
                maxlength="8"
                style={{ textTransform: 'uppercase' }}
                className="add-input"
              />
            </div>
            <div className="form-input">
              <label>Unit Name:</label>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                style={{ textTransform: 'Capitalize' }}
                className="add-input"
              />
            </div>
            <div className="form-input">
              <label>Description:</label>
              <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
                rows="4"
                cols="50"
              ></textarea>
            </div>
          </section>
          <section className="form-btn">
            <button type="submit">
              <Button $primary={true} label="Submit" />
            </button>
          </section>
        </form>
      </article>
    </main>
  );
}

const errorStyle = {
  color: 'red',
};

export default AddCoursePage;